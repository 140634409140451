import React from "react";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();
  return (
    <section className="container-fluid">
      <div className="row m-2-hor">
        <div className="col-md-6 pt-5">
          <div className="col-home">
            <div className="thumb">
              <img src="/img/hepsen/51 .jpeg" className="img-fluid" alt="#" />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="dflex-center">
            <div className="col-home mt-md-0 mt-5">
              <div className="heading">
                {t("bespokeInteriorsTailoredToYou")}
              </div>
              <div className="content">{t("content")}</div>
              <ul className="list-home">
                <li>{t("homeDesignsInterior")}</li>
                <li>{t("modularKitchenDesigns")}</li>
                <li>{t("wardrobeDesignInterior")}</li>
                <li>{t("spaceSavingFurniture")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
