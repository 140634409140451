import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const CustomSlide = ({ index, ...props }) => {
  return <div {...props}></div>;
};

const Responsive = () => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoPlay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slick">
      <Slider {...settings}>
        <CustomSlide className="itm" index={1}>
          <div className="bg">
            <img
              src="/img/hepsen/40 .jpeg"
              className="img-fluid"
              alt="Imageteam"
            />
          </div>
          <div className="desc">
            <div className="name">{t("interiorDesign")}</div>
            <div className="content">{t("contentInteriorDesign")}</div>
          </div>
        </CustomSlide>

        <CustomSlide className="itm" index={2}>
          <div className="bg">
            <img
              src="/img/hepsen/44 .jpeg"
              className="img-fluid"
              alt="Imageteam"
            />
          </div>
          <div className="desc">
            <div className="name">{t("renovations")}</div>
            <div className="content">{t("contentRenovations")}</div>
          </div>
        </CustomSlide>

        <CustomSlide className="itm" index={3}>
          <div className="bg">
            <img
              src="./img/hepsen/45 .jpeg"
              className="img-fluid"
              alt="Imageteam"
            />
          </div>
          <div className="desc">
            <div className="name">{t("customFurniture")}</div>
            <div className="content">{t("contentCustomFurniture")}</div>
          </div>
        </CustomSlide>

        <CustomSlide className="itm" index={4}>
          <div className="bg">
            <img
              src="./img/hepsen/53 .jpeg"
              className="img-fluid"
              alt="Imageteam"
            />
          </div>
          <div className="desc">
            <div className="name">Sustainable Design</div>
            <div className="content">
              We are committed to eco-friendly interior solutions that don't
              compromise on style. Our sustainable design approach incorporates
              energy-efficient materials, recycled elements, and environmentally
              conscious practices. We create beautiful, responsible living
              spaces that reduce your carbon footprint while enhancing your
              quality of life.
            </div>
          </div>
        </CustomSlide>
      </Slider>
    </div>
  );
};

export default Responsive;
