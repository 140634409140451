import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import { useTranslation } from "react-i18next";
import { sliderContent } from "../data/sliderContent";

export default () => {
  const { t } = useTranslation();

  return (
    <Slider className="slider-wrapper" autoplay={6000}>
      {sliderContent.map((item, index) => (
        <div
          key={index}
          className="slider-content"
          style={{
            background: `url('${item.image}') no-repeat center center`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div className="inner">
            <h1>{t(item.title)}</h1>
            <p>{t(item.description)}</p>
            <a
              href={item.link}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>
                <span className="shine"></span>
                <span>{t(item.button)}</span>
              </button>
            </a>
          </div>
        </div>
      ))}
    </Slider>
  );
};
