export const facebookLink = "https://www.facebook.com/hepsenrenovation/";
export const address =
  "60, Jalan Utara 4, Kawasan Perusahaan Mergong Barrage, 05050 Alor Setar, Kedah";
export const contactNo = "60124880777";
export const formatedContactNo = contactNo.replace(
  /(\d{4})(\d{4})(\d{3})/,
  "$1 - $2 $3"
);
export const callLink = "tel:" + contactNo;
export const whatsappLink = "https://wa.me/" + contactNo;
export const sliderContent = [
  {
    title: "modernMinimalistLiving",
    description: "sleekDesignMeetsComfort",
    button: "callNow",
    link: callLink,
    image: "./img/hepsen/6 .jpeg",
  },
  {
    title: "elegantContemporaryLiving",
    description: "blendingStyleAndFunctionality",
    button: "whatsappNow",
    link: whatsappLink,
    image: "./img/hepsen/2 .jpeg",
  },
  {
    title: "smartSpaceUtilization",
    description: "seamlessIntegration",
    button: "freeConsultation",
    link: callLink,
    image: "./img/hepsen/7 .jpeg",
  },
];
