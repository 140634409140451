import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  address,
  callLink,
  facebookLink,
  formatedContactNo,
  whatsappLink,
} from "../data/sliderContent";
import {
  Body,
  Container,
  LinkTag,
  Overlaybg,
  Page,
  SocialContainer,
  Wrapper,
} from "../styles/Navigation.styles";
import NavButton from "./NavButton";
import { logoPath } from "./data";

const NavigationMenu = ({ hasBackground, setBackground, firstTime }) => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const { lang } = useParams();
  const { t, i18n } = useTranslation();

  const [isOn, setState] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [linking, setLink] = useState("");
  // useEffect(() => {
  //   const initializeLanguage = async () => {
  //     if (lang && (lang === "en" || lang === "zh")) {
  //       console.log("Initializing language:", lang);
  //       await i18n.changeLanguage(lang);
  //       console.log("Language initialized to:", i18n.language);
  //     }
  //   };

  //   initializeLanguage();
  // }, [lang, i18n]);

  useEffect(() => {
    // This effect will run after the language has been initialized
    console.log("Current language:", i18n.language);
    // You might want to force a re-render here if needed
    // setForceUpdate(prev => !prev);
  }, [i18n.language]);

  useEffect(() => {
    !!linking &&
      setTimeout(() => {
        switch (linking) {
          case "home":
            navigate("/");
            setState(false);
            setLink("");
            break;
          case "about":
            navigate("/about");
            setState(false);
            setLink("");
            break;
          case "work":
            navigate("/work");
            setState(false);
            setLink("");
            break;
          case "news":
            navigate("/news");
            setState(false);
            setLink("");
            break;
          case "contact":
            navigate("/contact");
            setState(false);
            setLink("");
            break;
          default:
            setLink("");
        }
        setBackground(false);
        window.scrollTo(0, 0);
      }, 0);
  }, [linking, setBackground, navigate]);

  useEffect(() => {
    shouldAnimate &&
      !isOn &&
      setTimeout(() => {
        setShouldAnimate(false);
      }, 0);
  }, [shouldAnimate, isOn]);

  const closeHandler = () => {
    setShouldAnimate(true);
    setState(!isOn);
  };

  const setLinkHandler = (path) => {
    navigate(path);
  };
  const changeLanguage = (lng) => {
    console.log("Changing language to:", lng);
    i18n
      .changeLanguage(lng)
      .then(() => {
        console.log("Language changed successfully to:", i18n.language);
      })
      .catch((error) => {
        console.error("Error changing language:", error);
      });
  };
  useEffect(() => {
    const header = document.getElementById("header");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.scrollY > sticky) {
        header && header.classList.add("sticky");
        totop && totop.classList.add("show");
      } else {
        header && header.classList.remove("sticky");
        totop && totop.classList.remove("show");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);
  const preventDefault = useCallback((e) => {
    e.preventDefault();
  }, []);
  const disableScroll = useCallback(() => {
    document.body.addEventListener("touchmove", preventDefault, {
      passive: false,
    });
    document.body.addEventListener("wheel", preventDefault, { passive: false });
  }, [preventDefault]);

  const enableScroll = useCallback(() => {
    document.body.removeEventListener("touchmove", preventDefault);
    document.body.removeEventListener("wheel", preventDefault);
  }, [preventDefault]);

  useEffect(() => {
    if (isOn) {
      disableScroll();
    } else {
      enableScroll();
    }

    return () => {
      enableScroll();
    };
  }, [isOn, disableScroll, enableScroll]);
  return (
    <header>
      <div id="header"></div>
      <div className="logo">
        <img
          src={logoPath}
          className="img-fluid"
          alt="logo"
          style={{ height: "50px", cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
        <span className="callus">
          <a
            href={callLink}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {t("callUs")}: {formatedContactNo}
          </a>
        </span>
      </div>
      <Wrapper open={isOn} shouldAnimate={shouldAnimate}>
        <Overlaybg
          open={isOn}
          shouldAnimate={shouldAnimate}
          onClick={closeHandler}
        />
        <Container
          open={isOn}
          onClick={closeHandler}
          hasBackground={hasBackground}
        >
          <NavButton open={isOn} />
        </Container>
        <Body className="midwrpr" open={isOn} shouldAnimate={shouldAnimate}>
          <div className="conPage">
            <Page
              className="mainBtn"
              variant="home"
              onClick={() => setLinkHandler("/")}
            >
              <LinkTag>{t("home")}</LinkTag>
            </Page>
            <Page
              className="mainBtn"
              variant="about"
              onClick={() => setLinkHandler("/about")}
            >
              <LinkTag>{t("about")}</LinkTag>
            </Page>
            {/* <Page
              className="mainBtn"
              variant="work"
              onClick={() => setLinkHandler("work")}
            >
              <LinkTag>Projects</LinkTag>
            </Page> */}
            {/* <Page
              className="mainBtn"
              variant="about"
              onClick={() => setLinkHandler("news")}
            >
              <LinkTag>News</LinkTag>
            </Page> */}
            <Page
              className="mainBtn"
              variant="about"
              // onClick={() => setLinkHandler("contact")}
            >
              <LinkTag href={callLink}>{t("callNow")}</LinkTag>
            </Page>
            <Page
              className="mainBtn"
              variant="about"
              // onClick={() => setLinkHandler("contact")}
            >
              <LinkTag href={whatsappLink}>{t("whatsappUs")}</LinkTag>
            </Page>
          </div>

          <div className="info">
            <span>
              <a href={callLink}>
                {t("callUs")}: {formatedContactNo}
              </a>
            </span>

            <span>{address}</span>
            <span>
              <button onClick={() => changeLanguage("en")}>EN</button>
              <button onClick={() => changeLanguage("zh")}>中文</button>
            </span>
          </div>
        </Body>
        <SocialContainer className="soc-icon" open={isOn}>
          <span>{t("followUs")}:</span>
          <span
            className="socicon"
            onClick={() => window.open(facebookLink, "_blank")}
          >
            <i className="fa fa-facebook-f"></i>
          </span>
          {/* <span className="socicon">
            <i className="fa fa-linkedin"></i>
          </span>
          <span className="socicon">
            <i className="fa fa-twitter"></i>
          </span>
          <span className="socicon">
            <i className="fa  fa-instagram"></i>
          </span> */}
        </SocialContainer>
      </Wrapper>
    </header>
  );
};

export default NavigationMenu;
