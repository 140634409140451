import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Abouthome from "../components/Abouthome";
import Footer from "../components/Footer";
import { Efect, Efect1, Efect2 } from "../styles/effect.styles";

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const fadeInElements = document.querySelectorAll(".fade-in");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    fadeInElements.forEach((el) => observer.observe(el));

    return () => fadeInElements.forEach((el) => observer.unobserve(el));
  }, []);

  return (
    <div>
      <Efect />
      <Efect1 />
      <Efect2 />

      <div className="jumbotron head" />

      <section className="jumbotron imgtop fade-in">
        <img src="/img/hepsen/11 .jpeg" className="img-fluid" alt="#" />
      </section>

      <section className="container-fluid pb-0 fade-in">
        <div className="row m-2-hor">
          <div className="col-md-4">
            <h1>{t("aboutHepsenFurnitureAndConstruction")}</h1>
          </div>
          <div className="col-md-8">
            <div className="content">{t("aboutUsContent")}</div>
          </div>
        </div>
      </section>

      <div className="fade-in">
        <Abouthome />
      </div>

      <Footer />
    </div>
  );
};

export default About;
