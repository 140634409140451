import React from "react";
import { useTranslation } from "react-i18next";
import { callLink, whatsappLink } from "../data/sliderContent";

export default () => {
  const { t } = useTranslation();

  return (
    <section className="container-fluid bggray py-4">
      <div className="row m-2-hor">
        <div className="col-lg-6 col-md-6">
          <div className="featurehome">
            <a
              href={callLink}
              style={{ textDecoration: "none", color: "inherit" }}
              className="img"
            >
              <div className="img">
                <i className="fa fa-phone"></i>
              </div>
            </a>
            <a
              href={callLink}
              style={{ textDecoration: "none", color: "inherit" }}
              className="text"
            >
              <div className="text">{t("getConsultant")}</div>
            </a>
          </div>
        </div>

        {/* <div className="col-lg-6 col-md-6">
          <div className="featurehome">
            <div className="img">
              <i class="fa fa-list-alt"></i>
            </div>
            <div className="text">Set Schedule</div>
          </div>
        </div> */}

        {/* <div className='col-lg-3 col-md-6'>
            <div className='featurehome'>
              <div className='img'>
                <i className="fa fa-cogs"></i>
              </div>
              <div className='text'>
                Set
                Package
              </div>
            </div>
          </div> */}

        <div className="col-lg-6 col-md-6">
          <div className="featurehome last">
            <a
              href={whatsappLink}
              style={{ textDecoration: "none", color: "inherit" }}
              className="img"
            >
              <div className="img">
                <i className="fa fa-whatsapp"></i>
              </div>
            </a>
            <a
              href={whatsappLink}
              style={{ textDecoration: "none", color: "inherit" }}
              className="text"
            >
              <div className="text">{t("leaveUsAMessage")}</div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
