import React, { useEffect, useRef } from "react";
import Abouthome from "../components/Abouthome";
import Carouselfeature from "../components/Carouselfeature";
import Featurehome from "../components/Featurehome";
import Footer from "../components/Footer";
import NavigationMenu from "../components/Nav";
import Slider from "../components/Sliderhome";
import { Efect, Efect1, Efect2 } from "../styles/effect.styles";

const Home = ({ firstTime, hasBackground, setBackground }) => {
  const fadeRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.4 }
    );

    const currentRefs = fadeRefs.current;
    currentRefs.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      currentRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <>
      <NavigationMenu
        firstTime={firstTime}
        hasBackground={hasBackground}
        setBackground={(state) => setBackground(state)}
      />
      <div>
        <Efect />
        <Efect1 />
        <Efect2 />

        <section className="jumbotron jumbomain">
          <Slider />
        </section>

        <Featurehome />

        <div ref={(el) => (fadeRefs.current[0] = el)} className="fade-in">
          <Abouthome />
        </div>

        <div ref={(el) => (fadeRefs.current[1] = el)} className="fade-in">
          <section className="container-fluid">
            <div className="row m-2-hor">
              <div className="col-12">
                <div className="heading">Our Services</div>
              </div>
              <div className="col-12">
                <Carouselfeature />
              </div>
            </div>
          </section>
        </div>

        {/* <Reveal effect="fadeInUp">
        <section className="container-fluid py-0">
          <div className="row m-2-hor">
            <div className="col-12">
              <Carouselclient />
            </div>
          </div>
        </section>
      </Reveal> */}

        {/* <Reveal effect="fadeInUp">
        <section className="container-fluid">
          <div className="row m-2-hor">
            <div className="col-12">
              <Carouselclientb />
            </div>
          </div>
        </section>
      </Reveal> */}

        {/* <Reveal effect="fadeInUp">
        <section className="container-fluid py-0">
          <div className="row m-2-hor">
            <div className="col-12">
              <div className="heading">Our Projects</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-0">
              <Carouselprojects />
            </div>
          </div>
        </section>
      </Reveal> */}

        {/* <Reveal effect="fadeInUp">
        <section className="container-fluid">
          <div className="row m-2-hor">
            <div className="col-12">
              <div className="heading">Our Team</div>
            </div>
            <div className="col-12">
              <Carouselteam />
            </div>
          </div>
        </section>
      </Reveal> */}

        {/* <Reveal effect="fadeInUp">
        <Accomplish />
      </Reveal> */}

        {/* <Reveal effect="fadeInUp">
        <section className="container-fluid pt-0">
          <div className="row m-2-hor">
            <div className="col-12">
              <div className="heading">Latest News</div>
            </div>
            <div className="col-12">
              <Carouselnews />
            </div>
          </div>
        </section>
      </Reveal> */}

        <Footer />
      </div>
    </>
  );
};

export default Home;
